<div id="main" class="main-sidebar" [class.mat-width]="showFiller">
    <!-- <div class="slider-container" (click)="showFiller = !showFiller">
        <em [appSvgIcon]="'leftCompactArrow'"></em>
    </div> -->
    <mat-drawer-container class="example-container mat-typography" autosize
        [ngClass]="[userRoles.indexOf('ROLE_SUPER_ADMIN') > -1 || userRoles.indexOf('ROLE_ON_BOARDING') > -1 ? 'adminSidebar' : '' ,switchView?'':'adminSidebar', panelState ? 'showSidebar' : 'hideSidebar']">
        <!-- <app-header class="header" (sidenav)="drawer.toggle()" (smallSidenav)="showFiller = !showFiller"></app-header> -->
        <!-- [style.backgroundColor]="theme" -->

        <mat-drawer [class.mat-width]="showFiller" #drawer class="example-sidenav" disableClose="true" opened="true"
            opened [mode]="value" (window:resize)="onResize($event)">
            <span class="sidebar-close" (click)="closeSidebar()" *ngIf="panelState"></span>
            <div class="app-logo">
                <img src="assets/images/sidebar/logo.png" alt="logo" class="w-100">
                <!-- <span class="colapseIcon" ><em [appSvgIcon]="'doubleLeftIcon'"></em></span> -->
            </div>
            <ul id="acc" class="nav sidebar flex-column">

                <!-- <li class="nav-item" *ngIf="dashboard" [class.activeCard]="!activeCard" [class.activeCard]="!activeCard">
                    <a  class="accordion-button nav-link collapsed" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"  >
                        <em [appSvgIcon]="'dashboard'" data-toggle="tooltip" data-placement="top" title="Dashboard" ></em>
                        <small *ngIf="showFiller" style="margin-left: 5px;" >Dashboard</small>
                    </a>
                    <ul id="flush-collapseOne" class="accordion-collapse p-0 collapse" data-bs-parent="#acc" >
                        <ul class="nav flex-column">
                            <li  class="nav-item m-0" [class.activeCard]="!activeCard">
                                <a [routerLinkActive]="activeCard? 'active-sub' : ''" class="nav-link  main-nav"  [routerLink]="!activeCard?'/payment-module/payment':'/dashboard'"[routerLinkActive]="activeCard? 'active':''">

                                    <small *ngIf="showFiller">Dashboard</small>
                                </a>
                            </li>
                        </ul>
                    </ul>
                </li> -->
                <li (click)="closeSidebar();toggle('')" [class.activeCard]="!activeCard" *ngIf="userRoles.indexOf('ROLE_SUPER_ADMIN') > -1 || userRoles.indexOf('ROLE_ORGANIZATION_ADMIN') > -1 || userRoles.indexOf('ROLE_USER') > -1 || userRoles.indexOf('ROLE_ADMIN') > -1 || userRoles.indexOf('ROLE_BILLING_MANAGER') > -1 || userRoles.indexOf('ROLE_CONTENT_MANAGER') > -1"
                    [routerLink]="!activeCard?'/payment-module/payment':'/dashboard'"
                    [routerLinkActive]="activeCard? 'active':''">
                    <a><em [appSvgIcon]="'dashboard'"></em><span>Dashboard</span></a>
                </li>
                <ng-container
                    *ngIf="userRoles.indexOf('ROLE_SUPER_ADMIN') > -1 || userRoles.indexOf('ROLE_ON_BOARDING') > -1 && showMenu">
                    <!-- <ng-container *ngIf="userRoles === ('ROLE_SUPER_ADMIN')"> -->
                    <li *ngIf="customers" (click)="closeSidebar();toggle('')" routerLink="/customer-module/customer"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'contact'"></em><span>Customers</span></a>
                    </li>

                    <!-- <li routerLink="/email-templates-module/" routerLinkActive="active">
                        <a><em [appSvgIcon]="'contact'"></em><span>Email Templates</span></a>
                    </li> -->

                    <!-- <li (click)="closeSidebar()" routerLink="/email-templates-module/template-type/" routerLinkActive="active">
                        <a><em [appSvgIcon]="'contact'"></em><span>Email Templates</span></a>
                    </li> -->

                    <li *ngIf="emailTemp" (click)="closeSidebar();toggle('')" routerLink="/email-templates-module/template-type/"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'email'"></em><span>Email Templates</span></a>
                    </li>

                    <li *ngIf="subscription" (click)="closeSidebar();toggle('')" routerLink="/subscription"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'subscription'"></em><span>Subscription</span></a>
                    </li>
                    <li *ngIf="accounting" (click)="closeSidebar();toggle('')" routerLink="/accounting-module/accounting"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'accounting'"></em><span>Accounting</span></a>
                    </li>
                    <li *ngIf="userRoles.indexOf('ROLE_SUPER_ADMIN') > -1" (click)="closeSidebar();toggle('')" routerLink="/onboarding-module/onboarding" routerLinkActive="active">
                        <a><em [appSvgIcon]="'onboardingUser'"></em><span>Onboarding Users</span></a>
                    </li>
                    <li *ngIf="onBoardinguser && userRoles.indexOf('ROLE_ON_BOARDING') > -1" (click)="closeSidebar();toggle('')" routerLink="/onboarding-module/onboarding" routerLinkActive="active">
                        <a><em [appSvgIcon]="'onboardingUser'"></em><span>Onboarding Users</span></a>
                    </li>
                    <li *ngIf="securityGroup" (click)="closeSidebar();toggle('')" routerLink="/security-module/security"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'security'"></em><span>Security Group</span></a>
                    </li>
                    <li *ngIf="uploadStock" (click)="closeSidebar();toggle('')" routerLink="/stock-module/upload-stock"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'stock'"></em><span>Upload Stock</span></a>
                    </li>

                    <li *ngIf="request" (click)="closeSidebar();toggle('')" routerLink="/requestcutomer-module/requestcustomer"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'request'"></em><span>Request</span></a>
                    </li>

                    <li *ngIf="transcode"  (click)="closeSidebar();toggle('')"
                        routerLink="/transcoding-module/transcoding" routerLinkActive="active">
                        <a><em [appSvgIcon]="'transcoding'"></em><span>Transcoding</span></a>
                    </li>
                    <li [routerLinkActive]="activeCard? 'active' : ''"
                        [routerLink]="'data-usage-module/myDataUsage/dataUsageList'"
                        [class.activeCard]="!activeCard">
                        <a (click)="toggle('dataUsage')" [attr.aria-expanded]="!isCollapsedDataUsage"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'app'"></em> <span>Data Usage</span>
                            <em class="arrowIcon" *ngIf="isCollapsedDataUsage" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedDataUsage" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedDataUsage">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a   [routerLink]="'data-usage-module/myDataUsage/dataUsageList'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Data Usage List</small>
                            </a>

                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="'data-usage-module/myDataUsage/costCalculator'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Cost Calculator</small>
                            </a>
                        </li>
                    </ul>
                    <li *ngIf="settings" (click)="closeSidebar();toggle('')" routerLink="/admin-setting-module/admin-setting"
                        routerLinkActive="active">
                        <a><em [appSvgIcon]="'setting'"></em><span>Settings</span></a>
                    </li>
                    <li  (click)="closeSidebar()" routerLink="/AI/chat-support/chp">
                        <a (click)="toggle('Support')" [ngClass]="[showactive ? 'active' : '']"
                        [attr.aria-expanded]="!isCollapsedMedia" aria-controls="collapseExample"> <em
                            [appSvgIcon]="'chatSupport'"></em> <span>AI Support</span>
                        <em class="arrowIcon" *ngIf="isCollapsedMedia" [appSvgIcon]="'downArrow'"></em>
                        <em class="arrowIcon" *ngIf="!isCollapsedMedia" [appSvgIcon]="'upArrow'"></em>
                    </a>
                </li>
                <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedMedia">

                    <li class="nav-item m-0" [class.activeCard]="!activeCard">
                        <a routerLink="/AI/chat-support/chp"
                            (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub':''"
                            class="nav-link">
                            <small>Chat History</small>
                        </a>
                    </li>

                    <li class="nav-item m-0" [class.activeCard]="!activeCard">
                        <a routerLink="/AI/chat-support/training-data"
                            (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub':''"
                            class="nav-link">
                            <small>Training Data</small>
                        </a>
                    </li>
                    <li class="nav-item m-0" [class.activeCard]="!activeCard">
                        <a routerLink="/AI/chat-support/unTrained-data"
                            (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub':''"
                            class="nav-link">
                            <small>UnTrained Data</small>
                        </a>
                    </li>


                </ul>

                </ng-container>
                <ng-container
                    *ngIf="userRoles.indexOf('ROLE_ORGANIZATION_ADMIN') > -1 || userRoles.indexOf('ROLE_USER') > -1 || userRoles.indexOf('ROLE_ADMIN') > -1 || userRoles.indexOf('ROLE_BILLING_MANAGER') > -1 || userRoles.indexOf('ROLE_CONTENT_MANAGER') > -1 ">
                    <!-- <ng-container *ngIf="userRoles.indexOf('ROLE_ORGANIZATION_ADMIN') > -1 "> -->


                    <li [routerLinkActive]="activeCard? 'active' : ''"


[routerLink]="!activeCard ? '/payment-module/payment' : showFolder ? '/library-module/library/folder'
: showVOD ? '/media-module/media/on-demand' : showMusic ? '/media-module/media/music' :
showBookStore ? '/media-module/media/ebook' : showPodcast ? '/media-module/media/podcast' : showLinks ?  '/library-module/library/links' : showRss ? '/library-module/library/rss-feeds' : '/media-module/media/analytics' "
                        [class.activeCard]="!activeCard" *ngIf="showMedia">
                        <a (click)="toggle('media')" [ngClass]="[showactive ? 'active' : '']"
                            [attr.aria-expanded]="!isCollapsedMedia" aria-controls="collapseExample"> <em
                                [appSvgIcon]="'media'"></em> <span>Media Assets</span>
                            <em class="arrowIcon" *ngIf="isCollapsedMedia" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedMedia" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedMedia" *ngIf="showMedia">

                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showFolder">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/library-module/library/folder'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Folder</small>
                            </a>
                        </li>

                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showVOD">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/on-demand'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Videos</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showMusic">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/music'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Music</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showBookStore">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/ebook'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Bookstore</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showPodcast">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/podcast'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Podcasts</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showLinks">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/library-module/library/links'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Links</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showRss">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/library-module/library/rss-feeds'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>RSS Feeds</small>
                            </a>
                        </li>

                        <!-- <li  class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showLive">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/live-streaming'" (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''" class="nav-link" >

                                <small >Live Streaming</small>
                            </a>
                        </li> -->
                        <!-- <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showEmbed">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/embeds'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <small>Embeds</small>
                            </a>
                        </li> -->
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showAnalytics">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/media-module/media/analytics'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Media Analytics</small>
                            </a>
                        </li>
                    </ul>

                    <li [routerLinkActive]="activeCard? 'active' : ''" (click)="closeSidebar()"
                        [routerLink]="!activeCard ? '/payment-module/payment': '/media-module/media/live-streaming'"
                        [class.activeCard]="!activeCard" *ngIf="showLiveStream && showLive">
                        <a (click)="toggle('')" [attr.aria-expanded]="!isCollapsedMedia"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'liveStream'"></em> <span>Live Streaming</span>
                            <!-- <em class="arrowIcon" *ngIf="isCollapsedMedia" [appSvgIcon]="'downArrow'"></em>
                       <em class="arrowIcon" *ngIf="!isCollapsedMedia" [appSvgIcon]="'upArrow'"></em> -->
                        </a>
                    </li>

                    <li *ngIf="showChannel && showHideChannel" (click)="toggle('');closeSidebar()" [class.activeCard]="!activeCard || !showChannel"
                    [routerLink]="!activeCard?'/payment-module/payment':'/channel-module/myChannel'"
                    [routerLinkActive]="activeCard? 'active':''"><a><em
                            [appSvgIcon]="'media'"></em><span>Channels</span></a></li>

                    <li [routerLinkActive]="activeCard? 'active' : ''"
[routerLink]="!activeCard ? '/payment-module/payment' : (showMobileApp) ? '/apps-module/apps/mobileApp'
: showTvApp ? '/apps-module/apps/tvApp' : showNotification ? '/apps-module/apps/pushNotification' : '/apps-module/apps/analytics' "
                        [class.activeCard]="!activeCard" *ngIf="showApps">
                        <a (click)="toggle('apps')" [attr.aria-expanded]="!isCollapsedApps"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'app'"></em> <span>Apps</span>
                            <em class="arrowIcon" *ngIf="isCollapsedApps" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedApps" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedApps">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf=" (showMobileApp && mobileAppAndroid && mobileAppIos) || (!mobileAppIos && mobileAppAndroid) || (mobileAppIos && !mobileAppAndroid)">
                            <a   [routerLink]="!activeCard ? '/payment-module/payment' : (showMobileApp ? '/apps-module/apps/mobileApp' : '/apps-module/apps/tvApp')"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Mobile App</small>
                            </a>

                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showTvApp">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/apps-module/apps/tvApp'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>TV App</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showNotification">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/apps-module/apps/pushNotification'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Push Notifications</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showAnalytics">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/apps-module/apps/analytics'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>App Analytics</small>
                            </a>
                        </li>
                    </ul>

                    <li *ngIf="showWeb" (click)="toggle('');closeSidebar()" [class.activeCard]="!activeCard"
                        [routerLink]="!activeCard?'/payment-module/payment':'/web-module/web'"
                        [routerLinkActive]="activeCard? 'active':''"><a><em
                                [appSvgIcon]="'web'"></em><span>Website</span></a></li>

                    <li [routerLinkActive]="activeCard? 'active' : ''"
                        [routerLink]="!activeCard?'/payment-module/payment': '/events-module/events'"
                        [class.activeCard]="!activeCard" *ngIf="showEvent">
                        <a (click)="toggle('events')" [attr.aria-expanded]="!isCollapsedEvents"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'event'"></em> <span>Events</span>
                            <em class="arrowIcon" *ngIf="isCollapsedEvents" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedEvents" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedEvents">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showEvent">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/events-module/events/eventList'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Events</small>
                            </a>
                        </li>
                        <!-- <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="showEmbed">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/events-module/events/embeds'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <small>Embeds</small>
                            </a>
                        </li> -->
                    </ul>

                    <li [routerLinkActive]="activeCard? 'active' : ''"
                        [routerLink]="!activeCard?'/payment-module/payment': '/giving-module/giving'"
                        [class.activeCard]="!activeCard" *ngIf="hideGiving">
                        <a (click)="toggle('giving')" [attr.aria-expanded]="!isCollapsedGiving"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'giving'"></em> <span>Giving</span>
                            <em class="arrowIcon" *ngIf="isCollapsedGiving" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedGiving" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedGiving">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="hideGiving">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/giving-module/giving/giving-list'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Giving</small>
                            </a>
                        </li>

                        <li class="nav-item m-0" [class.activeCard]="!activeCard" *ngIf="hideGiving">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/giving-module/giving/giving-statement-list'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Statement</small>
                            </a>
                        </li>

                    </ul>



                    <li *ngIf="showContact" (click)="toggle('');closeSidebar()" [class.activeCard]="!activeCard"
                        [routerLink]="!activeCard?'/payment-module/payment':'/contacts-module/contacts'"
                        [routerLinkActive]="activeCard? 'active':''"><a><em
                                [appSvgIcon]="'contact'"></em><span>Contacts</span></a></li>

                    <li [routerLinkActive]="activeCard? 'active' : ''"
                        [routerLink]="!activeCard?'/payment-module/payment': '/subscription-module/subscription-m'"
                        [class.activeCard]="!activeCard">
                        <a (click)="toggle('subscription')" [attr.aria-expanded]="!isCollapsedSubscription"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'subscription'"></em>
                            <span>Subscription</span>
                            <em class="arrowIcon" *ngIf="isCollapsedSubscription" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedSubscription" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedSubscription">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/subscription-module/subscription-m/subscription-plan'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Subscription Plan</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/subscription-module/subscription-m/subscribed-user'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Paid Subscribers</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/subscription-module/subscription-m/free-subscriber'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Free Subscribers</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/subscription-module/subscription-m/onetime-paidsubscriber'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>One-time Paid Subscribers</small>
                            </a>
                        </li>
                    </ul>

                    <li *ngIf="showSocialMedia" [routerLinkActive]="activeCard? 'active' : ''"
                        [class.activeCard]="!activeCard"
                        [routerLink]="!activeCard?'/payment-module/payment': '/social-module/social'">
                        <a (click)="toggle('socialMedia')" [attr.aria-expanded]="!isCollapsedSocialMedia"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'form'"></em> <span>Social Media</span>
                            <em class="arrowIcon" *ngIf="isCollapsedSocialMedia" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedSocialMedia" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedSocialMedia">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/social-module/social/social-media'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Social Media</small>
                            </a>
                        </li>
                        <!-- <li  class="nav-item m-0" [class.activeCard]="!activeCard">
                                            <a [routerLink]="!activeCard?'/payment-module/payment': '/social-module/social/lead-forms'" (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''" class="nav-link" >

                                                <small >Lead Ads</small>
                                            </a>
                                        </li> -->
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/social-module/social/lead-ad-forms'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Leads</small>
                            </a>
                        </li>
                    </ul>

                    <li *ngIf="showContactUs" [routerLinkActive]="activeCard? 'active' : ''"
                        [class.activeCard]="!activeCard"
                        [routerLink]="!activeCard?'/payment-module/payment': '/contactus-module/contactus-m'">
                        <a (click)="toggle('contactUs')" [attr.aria-expanded]="!isCollapsedContactUs"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'phone'"></em> <span>Contact Us</span>
                            <em class="arrowIcon" *ngIf="isCollapsedContactUs" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedContactUs" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" [(ngbCollapse)]="isCollapsedContactUs">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/contactus-module/contactus-m/settings'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Settings</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/contactus-module/contactus-m/querylist'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Messages</small>
                            </a>
                        </li>
                    </ul>

                    <li [routerLinkActive]="activeCard? 'active' : ''"
                        [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings'"
                        [class.activeCard]="!activeCard">
                        <a (click)="toggle('settings')" [attr.aria-expanded]="!isCollapsedSettings"
                            aria-controls="collapseExample"> <em [appSvgIcon]="'setting'"></em> <span>Settings</span>
                            <em class="arrowIcon" *ngIf="isCollapsedSettings" [appSvgIcon]="'downArrow'"></em>
                            <em class="arrowIcon" *ngIf="!isCollapsedSettings" [appSvgIcon]="'upArrow'"></em>
                        </a>
                    </li>
                    <ul #collapse="ngbCollapse" class="subMenus" style="padding-bottom: 80px !important;" [(ngbCollapse)]="isCollapsedSettings">
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/general-settings'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>General</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/developers-account'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Developer Accounts</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/app-store-info'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>App Store Info</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/tax-info'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Tax Info</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/branding'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Identity Management</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/users'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Users</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/subscription'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Subscription</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/stripe-settings'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Stripe Settings</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/mails'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Email</small>
                            </a>
                        </li>
                        <li class="nav-item m-0" [class.activeCard]="!activeCard">
                            <a [routerLink]="!activeCard?'/payment-module/payment': '/settings-module/settings/integrations-settings'"
                                (click)="closeSidebar()" [routerLinkActive]="activeCard? 'active-sub' : ''"
                                class="nav-link">
                                <!-- <i class="fas fa-bus"></i> -->
                                <small>Integration</small>
                            </a>
                        </li>
                    </ul>



                </ng-container>


            </ul>

        </mat-drawer>
    </mat-drawer-container>

</div>
